import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactSession } from "react-client-session";
import "./Register.css";
import axios from "axios";
import { useGoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { gapi } from "gapi-script";
import { useEffect } from "react";
import queryString from "query-string";

const Register = () => {
  const [showLoginPage, setShowLoginPage] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [spassword, setSpassword] = useState("");
  const [loginInfo, setLoginInfo] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState("");

  const createUserURL = process.env.REACT_APP_URL + "/createuser";
  const createUserOauthURL = process.env.REACT_APP_URL + "/createUserOauth";
  const loginUrl = process.env.REACT_APP_URL + "/login";
  const verifyOtp = process.env.REACT_APP_URL + "/verify_otp";
  const clientId =
    "104307482369-f236vgco3ghe91tm2d5qgoto5k4m6lbu.apps.googleusercontent.com";

  const apiPath = process.env.REACT_APP_URL;

  const navigate = useNavigate();

  const navigateToVerification = () => {
    navigate("/verify");
  };

  const navigateToTerms = () => {
    // 👇️ navigate to /contacts
    navigate("/terms-conditions");
  };

  const navigateToPin = () => {
    navigate("/master-pin");
  };

  const navigateToInterface = () => {
    navigate("/inventory");
  };

  function createUserFromEmail() {
    if (password.length < 8) {
      setLoginInfo("Password must have minimum 8 characters");
      return;
    }

    if (password !== spassword) {
      setLoginInfo("Passwords do not match");
      return;
    }
    axios
      .post(createUserURL, {
        email: email,
        password: password,
      }, {
        withCredentials: true
      })
      .then((response) => {
        console.log(response.data);
        ReactSession.set("email", email);
        navigateToVerification();
      })
      .catch((error) => {
        setLoginInfo(error.response.data.message);
      });
  }

  function loginFromEmail() {
    axios
      .post(loginUrl, {
        email: email,
        password: password,
      }, {
        withCredentials: true
      })
      .then((response) => {
        ReactSession.set("email", email);
        if (!response.data.verified) {
          navigateToVerification();
          return;
        }
        if (response.data.acceptedTerms) {
          if (response.data.hasWallet) {
            navigateToInterface();
          } else {
            navigateToPin();
          }
        } else if (response.data.hasWallet) {
          navigateToInterface();
        } else {
          navigateToTerms();
        }
      })
      .catch((error) => {
        if (error.response.data.message == "2fa required") {
          setShowOtp(true);
          return;
        }
        if (error.response.status === 401) {
          setLoginInfo("Invalid Login or Password");
        }
      });
  }

  function verify2fa() {
    axios
      .post(verifyOtp, {
        email: email,
        code: otp,
      }, {
        withCredentials: true
      })
      .then((response) => {
        if (response.status === 200) {
          loginFromEmail()
        }
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Login with Google functions

  const onSuccess = (res) => {
    axios
      .post(createUserOauthURL, {
        email: res.profileObj.email,
      }, {
        withCredentials: true
      })
      .then((response) => {
        console.log(response);
        ReactSession.set("email", res.profileObj.email);
        if (response.data.message === "Created account") {
          navigateToTerms();
        } else if (
          response.data.message === "Account exists, proceed to login"
        ) {
          if (response.data.acceptedTerms) {
            if (response.data.hasWallet) {
              navigateToInterface();
            } else {
              navigateToPin();
            }
          } else if (response.data.hasWallet) {
            navigateToInterface();
          } else {
            navigateToTerms();
          }
        }
      });
  };

  const onFailure = (err) => {
    console.log("failed:", err);
  };

  const { signIn } = useGoogleLogin({
    clientId: clientId,
    onSuccess: onSuccess,
    onFailure: onFailure,
  });

  // Login with Facebook function

  const responseFacebook = (res) => {
    axios
      .post(createUserOauthURL, {
        email: res.userID,
      }, {
        withCredentials: true
      })
      .then((response) => {
        console.log(response);
        ReactSession.set("email", res.userID);
        if (response.data.message === "Created account") {
          navigateToTerms();
        } else if (
          response.data.message === "Account exists, proceed to login"
        ) {
          if (response.data.acceptedTerms) {
            if (response.data.hasWallet) {
              navigateToInterface();
            } else {
              navigateToPin();
            }
          } else if (response.data.hasWallet) {
            navigateToInterface();
          } else {
            navigateToTerms();
          }
        }
      });
    console.log(res);
  };

  // Login with Twitter functions

  // const login = () => {
  //   (async () => {
  //     try {
  //       //OAuth Step 1
  //       const response = await axios({
  //         url: `${apiPath}/twitter/oauth/request_token`,
  //         method: "POST",
  //         withCredentials: true,
  //         httpOnly: true
  //       });

  //       const { oauth_token } = response.data;
  //       //Oauth Step 2
  //       window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${oauth_token}`;
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   })();
  // };

  const logout = () => {
    (async () => {
      try {
        await axios({
          url: `${apiPath}/twitter/logout`,
          method: "POST",
        });
      } catch (error) {
        console.error(error);
      }
    })();
  };

  // useEffect(() => {
  //   (async () => {
  //     const { oauth_token, oauth_verifier } = queryString.parse(
  //       window.location.search
  //     );
  //     console.log(oauth_token, oauth_verifier);
  //     if (oauth_token && oauth_verifier) {
  //       try {
  //         //Oauth Step 3
  //         await axios({
  //           url: `${apiPath}/twitter/oauth/access_token`,
  //           method: "POST",
  //           withCredentials: true,
  //           httpOnly: true,
  //           data: { oauth_token, oauth_verifier },
  //         });
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     }

  //     try {
  //       //Authenticated Resource Access
  //       const {
  //         data: { name, profile_image_url_https, status, entities },
  //       } = await axios({
  //         url: `${apiPath}/twitter/users/profile_banner`,
  //         method: "GET",
  //         withCredentials: true,
  //         httpOnly: true,
  //       });
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  }, []);

  return (
    <div className="register__wrapper">
      <div className="register">
        <div className="register__top">
          <div className="register__header">
            <div className="register__logo">
              <img
                src="/assets/logo-gif.gif"
                alt="Logo"
                className="register__logoGif"
              />
            </div>

            <div className="register__headerCenter">
              <span>{showLoginPage ? "Login" : "Create Account"}</span>
            </div>
          </div>
          <div className="register__signOptions">
            <FacebookLogin
              appId="460818729365842"
              callback={responseFacebook}
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  className="register__signOption register__facebook"
                >
                  <img src="assets/facebook.png" alt="facebook" /> Continue with
                  Facebook
                </button>
              )}
            />
            {/* <button
              onClick={login}
              className="register__signOption register__twitter"
            >
              <img src="assets/twitter.png" alt="twitter" /> Continue with
              Twitter
            </button> */}
            <button
              className="register__signOption register__google"
              onClick={signIn}
            >
              <img src="assets/google.png" alt="google" />
              Continue with Google
            </button>
          </div>
        </div>
        <div className="register__main">
          <span className="register__mainText">Or login with email</span>

          <form className="register__form" onSubmit={(e) => e.preventDefault()}>
            <input
              type="email"
              className="register__input"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <input
              type="password"
              className="register__input"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {showOtp && (
              <input
                type="number"
                className="register__input"
                placeholder="One Time Password"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            )}

            {!showLoginPage && (
              <input
                type="password"
                className="register__input"
                placeholder="Confirm Password"
                value={spassword}
                onChange={(e) => setSpassword(e.target.value)}
              />
            )}
            <div className="information-login">
              <div></div>
              <span className="warning-login">{loginInfo}</span>
              {showLoginPage ?(
                <>
                {showOtp ? <button className="aqua-btn end" onClick={verify2fa}>
                  Verify OTP
                </button> : <button className="aqua-btn end" onClick={loginFromEmail}>
                  Login
                </button> }
                </>
              )
                
               : 
                <button className="aqua-btn" onClick={createUserFromEmail}>
                  Create Account
                </button>
              }
            </div>
          </form>

          <span className="register__switchToLogin">
            {showLoginPage
              ? "Don't have an account?"
              : "Already have an account?"}{" "}
            {}
            <span
              onClick={() => {
                setShowLoginPage((prev) => !prev);
              }}
            >
              {showLoginPage ? "Register" : "Login"}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Register;
