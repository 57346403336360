import React from "react";
import { useGoogleLogout } from "react-google-login";
import { useNavigate } from "react-router-dom";

import "./ProfileDropdown.css";

export default function ProfileDropdown({ isActive }) {
  const clientId = "104307482369-f236vgco3ghe91tm2d5qgoto5k4m6lbu.apps.googleusercontent.com";

  const navigate = useNavigate();
  const navigateToSettings = () => {
    navigate("/inventory/settings/");
  };

  const navigateToHome = () => {
    navigate("/")
  }

  const onLogoutSuccess = () => {
    console.log('logout');
  };
  const onFailure = () => {
    console.log('logout fail');
  };
  const { signOut } = useGoogleLogout({
    clientId: clientId,
    onLogoutSuccess: onLogoutSuccess,
    onFailure: onFailure,
  });

  return (
    <div
      className={`profileDropdown ${
        isActive ? "profileDropdown__isActive" : ""
      }`}
    >
      <span onClick={navigateToSettings}>Settings</span>
      <span onClick={navigateToHome}>Logout</span>
      <span>Signin</span>
    </div>
  );
}
